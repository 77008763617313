<template>
  <div class="mt-8 space-y-6">
    <button 
      @click="$emit('back')" 
      class="flex items-center text-sm text-gray-400 hover:text-white"
    >
      <ArrowLeftIcon class="w-4 h-4 mr-1" />
      Back
    </button>

    <form @submit.prevent="handleSubmit" class="space-y-6">
        <div
      v-if="status"
      class="mb-4 font-medium text-sm text-green-600 bg-green-100 border border-green-400 rounded p-4"
    >
      {{ status }}
    </div>

        <FormInput
        id="email"
          label="Email address"
          v-model="form.email"
          type="email"
          required
            autofocus
            autocomplete="username"
            :error="form.errors.email"
        />
        <FormInput
          v-if="userType=== 'sender'"
          id="password"
          label="Password"
          v-model="form.password"
          type="password"
          required
          autocomplete="new-password"
          :error="form.errors.password"
        />
     
      <div class="flex items-center justify-between" v-if="userType=== 'sender'">
        <div class="flex items-center">
          <input
            id="remember-me"
            v-model="form.remember"
            type="checkbox"
            class="h-4 w-4 rounded border-gray-700 bg-gray-700 text-accent focus:ring-accent"
          />
          <label for="remember-me" class="ml-2 block text-sm text-gray-300">
            Remember me
          </label>
        </div>
 
        <div class="text-sm" >
          <a href="/forgot-password" class="font-medium text-accent hover:text-accent-secondary">
            Forgot your password?
          </a>
        </div>
      </div>

      <div>
        <button
          type="submit"
          class="w-full btn-primary"
        >
            {{  userType === 'sender' ? 'Sign in' : 'Send login link' }}  
        </button>
      </div>
    </form>

    <div class="text-center" v-if="userType=== 'sender'">
      <p class="text-sm text-gray-400">
        Don't have an account?
        <a href="/register" class="font-medium text-accent hover:text-accent-secondary">
          Sign up
        </a>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { ArrowLeftIcon } from '@heroicons/vue/24/outline'
import FormInput from '@/Components/FormInput.vue';
import { useForm } from '@inertiajs/vue3';

const props = defineProps<{
  userType: string,
  status: String
}>()


const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const handleSubmit = () => {
    if(props.userType === 'sender') {
        form.post(route('login'), {
            onFinish: () => form.reset('password'),
        });
        return;
    } else {
        form.post(route('listener.login'));
    }
    
};
</script>
